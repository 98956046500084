import React from 'react';

import Layout from '../components/Layout';
import headingimg from '../assets/images/headingimg.png';
import config from '../../config';
import Footer from '../components/Footer';

const IndexPage = () => (
  <Layout>
  <header id="header">
  <div className="content">
    <div className="image-header">
      <img src={headingimg} alt={config.heading} />
      {/* <h1>www.ddns.eus</h1> */}
    </div>

    <p>{config.subHeading}</p>
    <h2>404 - Page not found</h2>
    <p>
    Not a valid URL
    </p>
  </div>
</header>

<Footer />
</Layout>

);

export default IndexPage;
